<template>
  <el-card shadow="never">
    <template #header>
      <i class="el-icon-present"></i>&nbsp;
      <span>商品管理</span>
    </template>

    <div class="content">
      <div class="content-header">
        <el-row>
          <el-col>
            <el-button type="success" class="f-right" @click="addGoods()"
              >添加商品</el-button
            >
          </el-col>
        </el-row>
      </div>

      <div class="content-main">
        <el-table
          :data="goodslist"
          v-loading="loading"
          element-loading-text="拼命加载中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.6)"
          class="block-contents"
        >
          <el-table-column label="缩略图" width="80">
            <template #default="scope">
              <el-image class="ad-thumb" :src="scope.row.PdtThumb" />
            </template>
          </el-table-column>
          <el-table-column
            prop="Merchant"
            label="商户名称"
            show-overflow-tooltip
            width="140"
          />
          <el-table-column
            prop="PdtName"
            label="商品标题"
            show-overflow-tooltip
          />
          <el-table-column
            v-if="type == 'showcase' && subtype == 'menus'"
            prop="linkname"
            label="链接"
            show-overflow-tooltip
          />
          <el-table-column
            v-if="type == 'goodslist' && listtype == 2"
            label="热租指数"
            width="160"
          >
            <template #default="scope">
              <el-input-number
                v-model="scope.row.hotrent"
                controls-position="right"
                :min="0"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="320" class-name="cate_control">
            <template #default="scope">
              <el-button
                v-if="type == 'showcase' && subtype == 'menus'"
                type="text"
                @click="gmEditGoods(scope.$index)"
                >编辑</el-button
              >
              <el-button
                type="text"
                :class="scope.row.status == 1 ? 'delete' : ''"
                @click.stop="gmUpdateStatus(scope.$index)"
                >{{ scope.row.status == 0 ? "上架" : "下架" }}</el-button
              >
              <el-button type="text" @click="gmMoveUp(scope.$index)"
                >上移</el-button
              >
              <el-button type="text" @click="gmMoveDown(scope.$index)"
                >下移</el-button
              >
              <el-button
                type="text"
                @click="gmDelGoods(scope.$index)"
                class="delete"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-button type="primary" @click="saveGoodsList()">保 存</el-button>
      <el-button @click="goBack()">返 回</el-button>
    </div>
  </el-card>

  <LinkBlock
    :showlink="gmShowlink"
    linktype="goods"
    goodscount="1000"
    :selgoods="gmSelGoods"
    :goodsids="goodsidsarr"
    v-on:close="gmOnClose"
    v-on:submit="gmSetLink"
  ></LinkBlock>

  <el-dialog
    title="商品编辑器"
    v-model="editgoods"
    width="40%"
    @close="onCloseGoods"
  >
    <el-form
      :model="curform"
      label-width="100px"
      class="dataform"
      :rules="rules"
      ref="dataform"
    >
      <el-form-item label="标题" prop="PdtName">
        <el-input v-model="curform.PdtName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="商品图片" prop="PdtThumb">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="imageUploadSuccess"
          :on-change="imageUploadChange"
          :on-error="imgUploadError"
          :show-file-list="false"
          :auto-upload="false"
          ref="imageUpload"
        >
          <img
            v-if="curform.PdtThumb"
            :src="curform.PdtThumb"
            class="avatar"
            preview-src-list="[curform.PdtThumb]"
            hide-on-click-modal="{{true}}"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger">图片最佳尺寸：200px × 200px</el-tag>
      </el-form-item>
      <el-form-item label="链接">
        <el-input
          v-model="curform.linkname"
          autocomplete="off"
          readonly="true"
          class="readinput"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editgoods = false">取 消</el-button>
        <el-button type="primary" @click="saveGoods()">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>


<script>
import pageconst from "@/pageconst";
import { ElMessage, ElMessageBox } from "element-plus";
import LinkBlock from "@/components/LinkBlock";

export default {
  data() {
    return {
      uploadAction: pageconst.upload_pdt_detail_url,
      gmShowlink: false,
      query: {},
      pagename: "",
      type: "",
      subtype: "",
      config: {},
      goodsidsarr: [],
      goodsids: [],
      goodslist: [],
      gmSelGoods: "radio",
      editgoods: false,
      goodsindex: "",
      curform: {},
      orgform: "",
      rules: {
        PdtName: [
          { required: true, message: "请输入商品标题", trigger: "blur" },
        ],
      },
      menugoods: [],
      loading: false,
      listtype: 1,
    };
  },

  components: {
    LinkBlock,
  },

  methods: {
    addGoods() {
      let goodsidsarr = [];
      for (let i = 0; i < this.goodsids.length; i++) {
        goodsidsarr.push(this.goodsids[i].id);
      }
      this.goodsidsarr = goodsidsarr;
      this.gmShowlink = true;
      this.gmSelGoods = "checkbox";
    },

    gmOnClose(isclose) {
      this.gmShowlink = isclose;
      this.gmSelGoods = "radio";
    },

    gmSetLink(link, linkname, goodsid = "") {
      console.log(goodsid);
      this.axios
        .get(
          pageconst.pagemanage +
            "?entry=getgoodslist&goodsids=" +
            JSON.stringify(goodsid) +
            "&pagesize=" +
            goodsid.length,
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(({ data }) => {
          var { list } = data;
          console.log(list);
          let curgoodsids = [].concat(this.goodsids);
          for (let i = 0; i < list.length; i++) {
            let inarray = false;
            // 过滤重复商品
            /*for (let j = 0; j < curgoodsids.length; j++) {
              if (curgoodsids[j].id == list[i].ID) {
                inarray = true;
              }
            }*/

            if (!inarray) {
              let obj = new Object();
              obj.id = list[i].ID;
              obj.status = 1;
              curgoodsids.unshift(obj);
            }
          }

          console.log(curgoodsids);
          this.goodsids = curgoodsids;

          this.getGoodsList();
        });
    },

    saveGoodsList() {
      let config = this.config;

      if (this.pagename == "category") {
        let { rootindex, index } = this.query;
        //console.log(this.goodsids);
        if (index == -1) {
          config.category.value[rootindex].ad.goodsids = this.goodsids;
        } else {
          config.category.value[rootindex].children[index].goodsids =
            this.goodsids;
        }
      } else if (this.pagename == "my") {
        let { index } = this.query;
        config.ad.value[index].goodsids = this.goodsids;
      } else if (this.pagename == "index") {
        let { type, index } = this.query;
        if (type == "venue") {
          let { cmindex, itemindex } = this.$route.query;
          config[type].value[index].value[cmindex].items[itemindex].goodsids =
            this.goodsids;
        } else if (type == "showcase") {
          let { subtype, itemindex } = this.$route.query;
          config[type].value[index][subtype][itemindex].goodsids =
            this.goodsids;
          if (subtype == "menus") {
            let goods = [];
            for (let i = 0; i < this.goodslist.length; i++) {
              let obj = new Object();
              obj.goodsid = this.goodslist[i].ID;
              obj.thumb = this.goodslist[i].PdtThumb;
              obj.title = this.goodslist[i].PdtName;
              obj.linktype = "goods";
              obj.link = "/pages/goods/goods?pdtid=" + this.goodslist[i].ID;
              obj.linkname = this.goodslist[i].linkname;
              obj.status = this.goodslist[i].status;

              goods.push(obj);
            }
            config[type].value[index][subtype][itemindex].goods = goods;
          }
        } else if (type == "goodslist") {
          config[type].items[index].goodsids = this.goodsids;
        } else {
          if (config[type] && config[type].value[index]) {
            config[type].type = type;
            config[type].value[index].goodsids = this.goodsids;
          } else {
            config[type] = new Object();
            config[type].type = type;
            config[type].value = new Array();
            config[type].value[index] = new Object();
            config[type].value[index].goodsids = this.goodsids;
          }
        }
      } else if (this.pagename.indexOf("compage_") !== -1) {
        let { type, parentindex, index } = this.query;
        if (type == "venue") {
          let { cmindex, itemindex } = this.$route.query;
          config.blocks[index].items[cmindex].items[itemindex].goodsids =
            this.goodsids;
        } else if (type == "goodslist") {
          console.log("this.goodslist => ", this.goodslist);
          let goodsids = [];
          for(let i = 0; i < this.goodslist.length; i++) {
            let obj = new Object();
            obj.id = this.goodslist[i].ID;
            obj.status = this.goodslist[i].status;
            obj.hotrent = this.goodslist[i].hotrent;

            goodsids.push(obj);
          }

          console.log("this.goodsids => ", goodsids);
          config["blocks"][parentindex].items[index].goodsids = goodsids;
        } else if (type == "showcase") {
          let { blockindex, subtype, itemindex } = this.$route.query;
          config.blocks[blockindex].items[index][subtype][itemindex].goodsids =
            this.goodsids;
          if (subtype == "menus") {
            let goods = [];
            for (let i = 0; i < this.goodslist.length; i++) {
              let obj = new Object();
              obj.goodsid = this.goodslist[i].ID;
              obj.thumb = this.goodslist[i].PdtThumb;
              obj.title = this.goodslist[i].PdtName;
              obj.linktype = "goods";
              obj.link = "/pages/goods/goods?pdtid=" + this.goodslist[i].ID;
              obj.linkname = this.goodslist[i].linkname;
              obj.status = this.goodslist[i].status;

              goods.push(obj);
            }
            config.blocks[blockindex].items[index][subtype][itemindex].goods =
              goods;
          }
        } else {
          config[type][index].goodsids = this.goodsids;
        }
      }

      console.log(config);

      this.axios
        .post(
          pageconst.pagemanage +
            "?entry=saveconfig&pagename=" +
            this.pagename +
            "&from=goodsmanage",
          JSON.stringify(config),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(({ data }) => {
          if (data == "SUCCESS") {
            ElMessage({
              showClose: true,
              message: "保存成功",
              type: "success",
              onClose: function () {
                history.go(-1);
              },
            });
            console.log("保存成功");
          } else {
            ElMessage.error({
              showClose: true,
              message: data,
            });
            return false;
          }
        });
    },

    goBack() {
      history.go(-1);
    },

    getGoodsList() {
      let that = this;
      this.loading = true;
      this.axios
        .post(
          pageconst.pagemanage + "?entry=getgoodslistbyids",
          {
            goodsids: JSON.stringify(this.goodsids),
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(({ data }) => {
          let { list } = data;
          if (that.type == "showcase" && that.subtype == "menus") {
            let newlist = [];
            for (let i = 0; i < list.length; i++) {
              let obj = new Object();
              obj.ID = list[i].ID;
              obj.PdtName = list[i].PdtName;
              obj.Merchant = list[i].Merchant;
              obj.linkname = list[i].PdtName;
              obj.PdtThumb = list[i].PdtThumb;
              obj.status = 1;

              newlist.push(obj);
            }

            if (this.menugoods.length > 0) {
              for (let i = 0; i < newlist.length; i++) {
                for (let j = 0; j < this.menugoods.length; j++) {
                  if (newlist[i].ID * 1 == this.menugoods[j].goodsid * 1) {
                    newlist[i].PdtName = this.menugoods[j].title;
                    newlist[i].PdtThumb = this.menugoods[j].thumb;
                    newlist[i].status = this.menugoods[j].status;
                  }
                }
              }
            }
            list = newlist;
          }

          console.log("that.type => ", that.type);
          console.log("that.listtype => ", that.listtype);

          if (that.type == "goodslist" && that.listtype == 2) {
            for (let i = 0; i < list.length; i++) {
              if (isNaN(list[i].hotrent * 1)) {
                list[i].hotrent = 0;
              }
            }
          }

          console.log("goodslist => ", list);

          this.goodslist = list;

          this.loading = false;
        });
    },

    gmSwapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    gmMoveUp(index) {
      if (index != 0) {
        this.goodslist = [
          ...this.gmSwapArray(this.goodslist, index, index - 1),
        ];
        this.goodsids = [...this.gmSwapArray(this.goodsids, index, index - 1)];

        //console.log("gmMoveUp goodslist => ", this.goodslist);
        //console.log("gmMoveUp goodsids => ", this.goodsids);
      }
    },
    gmMoveDown(index) {
      if (index + 1 != this.goodslist.length) {
        this.goodslist = [
          ...this.gmSwapArray(this.goodslist, index, index + 1),
        ];
        this.goodsids = [...this.gmSwapArray(this.goodsids, index, index + 1)];
      }
    },
    gmDelGoods(index) {
      if (!this.goodslist[index]) return false;

      ElMessageBox.confirm(
        "您确定要删除 " + this.goodslist[index].PdtName + " 吗？",
        "系统提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.goodslist.splice(index, 1);
          this.goodsids.splice(index, 1);
        })
        .catch(() => {
          console.log("取消操作");
        });
    },
    gmUpdateStatus(index) {
      if (!this.goodslist[index]) return false;

      if (this.goodslist[index].status == 1) {
        ElMessageBox.confirm(
          "您确定要下架 " + this.goodslist[index].PdtName + " 吗？",
          "系统提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.goodslist[index].status = 0;
            this.goodsids[index].status = 0;
          })
          .catch(() => {
            console.log("取消操作");
          });
      } else {
        this.goodslist[index].status = 1;
        this.goodsids[index].status = 1;
      }
    },

    gmEditGoods(index) {
      if (!isNaN(index)) {
        this.curform = this.goodslist[index];
        this.goodsindex = index;
        this.orgform = JSON.stringify(this.curform);
      } else {
        this.curform = {};
        this.orgform = "";
        this.goodsindex = "";
      }
      this.editgoods = true;
    },

    onCloseGoods() {
      this.editgoods = false;
      if (this.orgform !== "") {
        this.goodslist[this.goodsindex] = JSON.parse(this.orgform);
      }
    },

    saveGoods() {
      if (this.goodsindex !== "") {
        this.$refs["dataform"].validate((res) => {
          if (res) {
            this.goodslist[this.goodsindex] = this.curform;
            this.orgform = "";
            this.editgoods = false;
          }
        });
      }
    },

    imageUploadChange(file) {
      let isSubmit = file.raw.size / 1024 / 1024 < 2;
      if (!isSubmit) {
        this.$message.error("产品缩略图大小不能超过 2MB!");
        return false;
      }

      if (isSubmit) this.$refs.imageUpload.submit();
    },
    imageUploadSuccess(res) {
      this.curform.PdtThumb = res.url;
    },
    imgUploadError(err) {
      console.log("err==>", err);
    },
  },

  created() {
    this.query = this.$route.query;

    let { pagename, type, rootindex, parentindex, index } = this.$route.query;
    this.pagename = pagename;
    this.type = type;

    console.log(pagename, type, rootindex, parentindex, index);

    this.axios
      .get(pageconst.pagemanage + "?entry=getconfig&pagename=" + pagename, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => {
        let { config } = data,
          goodsids = [];
        this.config = config;

        if (pagename == "category") {
          if (index == -1) {
            goodsids = config.category.value[rootindex].ad.goodsids
              ? config.category.value[rootindex].ad.goodsids
              : [];
          } else {
            goodsids = config.category.value[rootindex].children[index].goodsids
              ? config.category.value[rootindex].children[index].goodsids
              : [];
          }
        } else if (pagename == "my") {
          goodsids = config.ad.value[index].goodsids
            ? config.ad.value[index].goodsids
            : [];
        } else if (pagename == "index") {
          if (type == "venue") {
            let { cmindex, itemindex } = this.$route.query;
            goodsids = config[type].value[index].value[cmindex].items[itemindex]
              .goodsids
              ? config[type].value[index].value[cmindex].items[itemindex]
                  .goodsids
              : [];
          } else if (type == "showcase") {
            let { subtype, itemindex } = this.$route.query;
            this.subtype = subtype;
            goodsids = config[type].value[index][subtype][itemindex].goodsids
              ? config[type].value[index][subtype][itemindex].goodsids
              : [];
            if (subtype == "menus") {
              this.menugoods = config[type].value[index][subtype][itemindex]
                .goods
                ? config[type].value[index][subtype][itemindex].goods
                : [];
            }
          } else if (type == "goodslist") {
            goodsids =
              config[type].items &&
              config[type].items[index] &&
              config[type].items[index].goodsids &&
              config[type].items[index].goodsids.length > 0
                ? config[type].items[index].goodsids
                : [];
            this.listtype = config[type].mode;
          } else {
            console.log(config[type] ? config[type].value : []);
            goodsids =
              config[type] &&
              config[type].value.length > 0 &&
              config[type].value[index].goodsids
                ? config[type].value[index].goodsids
                : [];
          }
        } else if (pagename.indexOf("compage_") !== -1) {
          if (type == "venue") {
            let { cmindex, itemindex } = this.$route.query;
            goodsids = config.blocks[index].items[cmindex].items[itemindex]
              .goodsids
              ? config.blocks[index].items[cmindex].items[itemindex].goodsids
              : [];
          } else if (type == "goodslist") {
            goodsids = config["blocks"][parentindex].items[index].goodsids
              ? config["blocks"][parentindex].items[index].goodsids
              : [];
            this.listtype = config["blocks"][parentindex].mode;
          } else if (type == "showcase") {
            let { blockindex, subtype, itemindex } = this.$route.query;
            this.subtype = subtype;
            goodsids = config.blocks[blockindex].items[index][subtype][
              itemindex
            ].goodsids
              ? config.blocks[blockindex].items[index][subtype][itemindex]
                  .goodsids
              : [];
            if (subtype == "menus") {
              this.menugoods = config.blocks[blockindex].items[index][subtype][
                itemindex
              ].goods
                ? config.blocks[blockindex].items[index][subtype][itemindex]
                    .goods
                : [];
            }
          } else {
            goodsids = config[type][index].goodsids
              ? config[type][index].goodsids
              : [];
          }
        }

        this.goodsids = goodsids;

        console.log("init goodsids => ", goodsids);

        this.getGoodsList();
      });
  },
};
</script>

<style scoped>
.content-main {
  border: 1px solid #ebeef5;
  border-bottom: none;
  margin: 20px 0;
}

>>> .el-table th {
  height: 48px;
  line-height: 48px;
  font-weight: bold;
  color: #909399;
  font-size: 14px;
  padding: 0;
}

.tree-head .tree-head-one {
  padding-left: 8px;
  flex-grow: 1;
}

>>> .cate_control {
  text-align: right;
}

>>> .el-table .cell {
  max-height: 40px;
  line-height: initial;
}

.ad-thumb {
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  padding: 2px;
  border: 1px solid #ddd;
}

.cell .el-button {
  margin-right: 15px;
  margin-left: 0;
}
.cell .el-button:last-child {
  margin-right: 0;
}

.cell .el-button.delete {
  color: #f66;
}

.dataform >>> .avatar-uploader {
  line-height: 0;
}

.dataform >>> .avatar-uploader-icon {
  font-size: 40px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.dataform >>> .avatar {
  width: 100%;
  max-width: 200px;
  display: block;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}

.el-input-number {
  width: 100%;
}
</style>